"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getContractsForChainOrThrow = void 0;
const contracts_1 = require("@punks/contracts");
const addresses_1 = require("./addresses");
/**
 * Get contract instances that target the Ethereum mainnet
 * or a supported testnet. Throws if there are no known contracts
 * deployed on the corresponding chain.
 * @param chainId The desired chain id
 * @param signerOrProvider The ethers v5 signer or provider
 */
const getContractsForChainOrThrow = (chainId, signerOrProvider) => {
    const addresses = (0, addresses_1.getContractAddressesForChainOrThrow)(chainId);
    return {
        nTokenContract: contracts_1.NTokenFactory.connect(addresses.nToken, signerOrProvider),
        nAuctionHouseContract: contracts_1.NAuctionHouseFactory.connect(addresses.nAuctionHouseProxy, signerOrProvider),
        nDescriptorContract: contracts_1.NDescriptorFactory.connect(addresses.nDescriptor, signerOrProvider),
        nSeederContract: contracts_1.NSeederFactory.connect(addresses.nSeeder, signerOrProvider),
        nDaoContract: contracts_1.NDaoLogicV1Factory.connect(addresses.nDAOProxy, signerOrProvider),
    };
};
exports.getContractsForChainOrThrow = getContractsForChainOrThrow;
